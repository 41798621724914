import {
  EditorReadyFn,
  FlowEditorSDK,
  FlowPlatformOptions,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import { BM_PLAYGROUND, WIDGET_ID, WIX_CHAT_APP_DEF_ID } from './constants';
import {
  getComponentRef,
  getWidgetPosition,
  updateLayout,
} from './utils/positionUtils';

const OPPOSITE_POSITION = {
  left: 'right',
  right: 'left',
} as const;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  if (platformOptions.firstInstall) {
    const isWixChatInstalled =
      await editorSDK.document.application.isApplicationInstalled('', {
        appDefinitionId: WIX_CHAT_APP_DEF_ID,
      });

    if (!isWixChatInstalled) {
      return;
    }

    const [wixChatPosition, aiSiteChatPosition] = await Promise.all([
      getWidgetPosition({
        editorSDK,
        appDefId: WIX_CHAT_APP_DEF_ID,
      }),
      getWidgetPosition({
        editorSDK,
      }),
    ]);

    if (
      wixChatPosition &&
      aiSiteChatPosition &&
      wixChatPosition === aiSiteChatPosition
    ) {
      await updateLayout({
        editorSDK,
        position: OPPOSITE_POSITION[wixChatPosition],
      });
    }
  }
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const { t } = flowAPI.translations;

  appManifestBuilder.configureWidget(WIDGET_ID, (widgetBuilder) => {
    widgetBuilder.gfpp().set('mainAction2', {
      label: t('app.settings.gfpp.test'),
      onClick: ({ detail }) => {
        editorSDK.editor.openDashboardPanel('', {
          url: BM_PLAYGROUND,
          closeOtherPanels: true,
        });
      },
    });

    widgetBuilder.behavior().set({
      canReparent: false,
      duplicatable: false,
      pinnable: false,
      closable: false,
      removable: false,
      resizable: false,
      toggleShowOnAllPagesEnabled: false,
      rotatable: false,
    });
  });

  return appManifestBuilder.build();
};

export const exports = async (
  editorSDK: FlowEditorSDK,
  { initialAppData }: FlowPlatformOptions,
) => {
  return {
    private: {
      setPosition: async (position: 'left' | 'right') => {
        const componentRef = await getComponentRef(
          editorSDK,
          initialAppData.appDefinitionId,
        );

        if (!componentRef) {
          return;
        }

        await editorSDK.components.properties.update('token', {
          componentRef,
          props: {
            placement: `BOTTOM_${position.toUpperCase()}`,
          },
        });
      },

      getPosition: async () => {
        const componentRef = await getComponentRef(
          editorSDK,
          initialAppData.appDefinitionId,
        );

        if (!componentRef) {
          return;
        }

        const properties = await editorSDK.components.properties.get('token', {
          componentRef,
        });

        return properties?.placement;
      },
    },
  };
};
